import React, {useEffect, useState} from 'react'
import {Grid, Icon, List} from 'semantic-ui-react'

const POSITIVE_COLOR ='positive'
const NEGATIVE_COLOR = 'negative'
const WARNING_COLOR = 'warning'

const RECOMMENDED_DECLINE = 'Recommended Decline'
const RECOMMENDED_APPROVE = 'Recommended Approve'
const REFER = 'Refer'
const AUTO_APPROVE = 'Auto Approve'
const AUTO_DECLINE = 'Auto Decline'

const CODE_DR_R001 = 'DR_R001'
const CODE_DR_R002 = 'DR_R002'
const CODE_DR_D001 = 'DR_D001'
const CODE_DR_R003 = 'DR_R003'
const CODE_DR_A001 = 'DR_A001'

const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function calculateProbabilityOfDefault(score){
    if(score >= 951) return {value: 0.1, color: POSITIVE_COLOR }
    else if (score >= 901) return {value: 0.5, color: POSITIVE_COLOR }
    else if (score >= 851) return {value: 1, color: POSITIVE_COLOR }
    else if (score >= 801) return {value: 2, color: POSITIVE_COLOR }
    else if (score >= 751) return {value: 2.5, color: POSITIVE_COLOR }
    else if (score >= 701) return {value: 3, color: WARNING_COLOR }
    else if (score >= 651) return {value: 3.5, color: WARNING_COLOR }
    else if (score >= 601) return {value: 4, color: WARNING_COLOR }
    else if (score >= 551) return {value: 5, color: WARNING_COLOR }
    else if (score >= 501) return {value: 10, color: WARNING_COLOR }
    else if (score >= 401) return {value: 20, color: NEGATIVE_COLOR }
    else if (score >= 301) return {value: 30, color: NEGATIVE_COLOR }
    else if (score >= 201) return {value: 40, color: NEGATIVE_COLOR }
    else if (score >= 101) return {value: 40, color: NEGATIVE_COLOR } 
    else return {value: 50, color: NEGATIVE_COLOR }
}

function calculateRiskRating(score) {
    if(score >= 801) return {value:'A', color:POSITIVE_COLOR}
    else if (score >= 601) return {value:'B', color:POSITIVE_COLOR}
    else if (score >= 451) return {value:'C', color:WARNING_COLOR}
    else if (score >= 301) return {value:'D', color:WARNING_COLOR}
    else if (score >= 151) return {value:'E', color:NEGATIVE_COLOR}
    else return {value:'F', color:NEGATIVE_COLOR}
}
function calculateScoreDecision(score) {
    if(score >= 751) return {value: AUTO_APPROVE, color: POSITIVE_COLOR} 
    else if (score >= 651) return {value:RECOMMENDED_APPROVE, color: POSITIVE_COLOR}
    else if (score >= 451) return {value:REFER, color: WARNING_COLOR}
    else if (score >= 201) return {value: RECOMMENDED_DECLINE, color: NEGATIVE_COLOR}
    else return {value:AUTO_DECLINE, color: NEGATIVE_COLOR}
}

function calculateAgeScore(age) {
    let score
    if (age < 20){
            score = 0
    }
    else if (age > 20 && age < 28){
            score = 200
    }
    else if (age > 28 && age < 36){
            score = 300
    }
    else if (age > 36 && age < 45){
            score = 400
    }
    else if (age > 45){
            score = 550
    }
    else {
            score = 0
    }
    return score
}
function calculateEducationLevelScore(educationLevel) {
    let score
    switch (educationLevel) {
        case 'Primaria':
            score = 0
            break;
        case 'Universidad':
            score = 250
            break;
        case 'Masters o superior':
            score = 450
            break;
        default:
            score = 0
    }

    return score
}
function toDate(dateStr) {
    var parts = dateStr.split("-")
    return new Date(parts[2], parts[1] - 1, parts[0])
  }

const getAge = (DOB) => {
    const today = new Date();
    const birthDate = new Date(DOB);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
    }

    return age;
}
const collectedData = (data) => {
    return !data ? <Icon name='spinner' loading/> : <Icon name='check circle outline'/> 

}

const rules = [
    {code: CODE_DR_R001, description: 'Monto Solicitado > $25,000', decision: 'Prevent Auto Decision', color: WARNING_COLOR},
    {code: CODE_DR_R002, description: 'Monto de Entrada > 20% y Score Decision = \'Auto Decline\'', decision: 'Prevent Auto Decline', color: WARNING_COLOR},
    {code: CODE_DR_D001, description: 'Rechazo por Score Bajo', decision: AUTO_DECLINE, color: NEGATIVE_COLOR},
    {code: CODE_DR_R003, description: 'Revisión Manual por Score', decision: 'Prevent Auto Decision', color: WARNING_COLOR},
    {code: CODE_DR_A001, description: 'Aprobado por Score Alto', decision: AUTO_APPROVE, color: POSITIVE_COLOR},
]


function highDownPayment(reqAmount, downPayment){
    if((downPayment/ reqAmount) > 0.20) return true
}
const hitRule = (results) =>{
    if(results.questionnaire.find(q => q.var === 'reqAmount').input > 25000) {
        return rules.find(rule => rule.code===CODE_DR_R001)
    }
    else if(highDownPayment(results.questionnaire.find(q=> q.var ==='reqAmount').input, results.questionnaire.find(q=> q.var ==='downPayment').input) && results.scoreDecision.value === AUTO_DECLINE) {
        return rules.find(rule=> rule.code === CODE_DR_R002)
    } else if(results.scoreDecision.value === AUTO_DECLINE){
        return rules.find(rule=> rule.code === CODE_DR_D001)
    } else if(results.scoreDecision.value === RECOMMENDED_DECLINE || results.scoreDecision.value === RECOMMENDED_APPROVE || results.scoreDecision.value === REFER ){
        return rules.find(rule=> rule.code === CODE_DR_R003)
    } else if(results.scoreDecision.value === AUTO_APPROVE){
        return rules.find(rule=> rule.code === CODE_DR_A001)
    }
}


const calculatePayments = (amount, n, i) => {
    const d = (Math.pow(1 + i, n) - 1)/(i*(Math.pow(1 + i, n)))
    const p = amount / d
    return p.toFixed(2)
}
const ProcessingResults = (props) => {
    const {client, questionnaire, handleResults} = props
    const [results, setResults] = useState(null)
    const [tradData, setTradData] = useState(false)
    const [altData, setAltData] = useState(false)

    async function collectTraditionalData (){
        await sleep(2000)
        setTradData(true)
    }

    async function collectAlternativeData (){
        await sleep(3000)
        setAltData(true)
    }

    async function processResults(client, questionnaire) {
        await sleep(5000)
        const birthDate = toDate(client.birthDate)
        const age = getAge(birthDate)
        const ageScore = calculateAgeScore(age)
        const educationScore = calculateEducationLevelScore(client.educationLevel)
        const totalScore = educationScore + ageScore
        const scoreDecision = calculateScoreDecision(totalScore)
        const riskRating = calculateRiskRating(totalScore)
        const defaultProbability = calculateProbabilityOfDefault(totalScore)

        const rule = hitRule({scoreDecision, questionnaire})
        const reqAmount = questionnaire.find(q=> q.var === 'reqAmount').input
        const downPayment = questionnaire.find(q=> q.var === 'downPayment').input
        const loanTerm = questionnaire.find(q=> q.var === 'loanTerm').input


        const payments = calculatePayments(reqAmount - downPayment, loanTerm, 0.005)
        setResults ({totalScore, scoreDecision, riskRating, defaultProbability, rule, payments, loanTerm, reqAmount, downPayment})
    }

    useEffect(() => {
       collectTraditionalData()
        collectAlternativeData()
        processResults(client, questionnaire)
    }, [client])

    useEffect(()=>{
        handleResults(results)
    }, [results])

    return(
        <Grid.Row textAlign='left'>
            <List>
                <List.Item>
                    {collectedData(tradData)}
                    Procesando Documentos
                </List.Item>
                <List.Item>
                    {collectedData(tradData)}
                    Recolectando Datos Tradicionales
                </List.Item>
                <List.Item>
                    {collectedData(altData)}
                    Recolectando Datos Alternativos
                </List.Item>
                <List.Item>
                    {collectedData(results)}
                    Procesando AltScore
                </List.Item>
            </List>
        </Grid.Row>
    )
}

export default ProcessingResults