import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import MenuComponent from './Menu'
import BasicInfoComponent from './BasicInfo'
import ClientInfoComponent from './ClientInfo'
import DocumentsComponent from './Documents'
import ResultsComponent from './Results';
import {Switch, Route, Redirect} from 'react-router-dom'
import {Grid, Segment} from 'semantic-ui-react'
import {data} from '../mockData/basicInfo'

import './styles.css'

import {BASIC_INFO_URL, CLIENT_INFO_URL, DOCUMENTS_URL, RESULTS_URL} from './urls'

function Main () {
    const[questionnaire, setQuestionnaire] = useState(null)
    const[confirmBasic, setConfirmBasic] = useState(false)
    const [client, setClient] = useState(null)
    const [documents, setDocuments] = useState(null)
    const [activePage, setActivePage] = useState(BASIC_INFO_URL)
    useEffect(()=>{
        setQuestionnaire(data)
    })

    useEffect(()=>{
        if(confirmBasic) handleSetActivePage(CLIENT_INFO_URL)
    }, [confirmBasic])
    useEffect(()=>{
        if(client) handleSetActivePage(DOCUMENTS_URL)
    }, [client])
    useEffect(()=>{
        if(documents) handleSetActivePage(RESULTS_URL)
    }, [documents])


    function handleSetDocuments(documents){
        setDocuments(documents)
    }
    function handleSetActivePage(page){
        if (page === CLIENT_INFO_URL && !confirmBasic){
            setActivePage(activePage)  
        }
        else if (page === DOCUMENTS_URL && !client){
            setActivePage(activePage)  
        }
        else if (page === RESULTS_URL && !documents){
            setActivePage(activePage)  
        }
        else {
            setActivePage(page)
        }
    }
    function handleConfirmBasic(){
        setConfirmBasic(true)
        handleSetActivePage(CLIENT_INFO_URL)
    }

    function handleConfirmClient(client){
        setClient(client)
    }
    return (
        // <div className='ui vertical masthead center aligned segment'>
        <Segment className='main-segment' vertical>
            <MenuComponent activePage={activePage} setActivePage={handleSetActivePage}/>
                <Grid className='main-grid' verticalAlign='middle' textAlign='center'>
                    <Grid.Column style={{maxWidth: '50%', maxHeight:'100%'}}> 
                        {activePage===BASIC_INFO_URL &&questionnaire &&  <BasicInfoComponent questionnaire={questionnaire} handleConfirmBasic={handleConfirmBasic} readOnly={confirmBasic} confirmBasic={confirmBasic}/>}
                        {activePage===CLIENT_INFO_URL  && confirmBasic && <ClientInfoComponent handleConfirmClient={handleConfirmClient} client={client} readOnly={!!client}/>}
                        {activePage===DOCUMENTS_URL && client && <DocumentsComponent handleSetDocuments={handleSetDocuments} documents={documents} readOnly={!!documents}/>}
                        {activePage===RESULTS_URL && client && documents && <ResultsComponent client={client} questionnaire={questionnaire}/>}
                    </Grid.Column>
                </Grid>
        </Segment>
        // </div>
        
    )
  
}

Main.propTypes = {
  basicInfo: PropTypes.object,
}


export default Main